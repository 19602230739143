import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";

import "./Banner.css";

const Banner = () => {
  const imageArray = [
    "https://img.lb.wbmdstatic.com/vim/live/webmd/consumer_assets/site_images/article_thumbnails/BigBead/coolsculpting_bigbead/1800x1200_coolsculpting_bigbead.jpg?resize=750:*",
    "https://assets.clevelandclinic.org/transform/LargeFeatureImage/c17ca8a1-6fbb-4078-a7d5-d838e7ae2009/Cool-Sculpting-1148533630-770x533-1_jpg",
    "https://sdbotox.com/wp-content/uploads/2023/12/Coolsculpting.jpeg",
    "https://divinitymedspa.co/wp-content/uploads/2017/10/coolsculpting.jpeg",
    "https://www.admireaesthetics.com/wp-content/uploads/2022/06/CoolSculpting-elite-applicators-admire-aesthetics.jpg",
    "https://iuventusmedcenter.com/wp-content/uploads/2019/03/9.jpg",
  ];
  return (
    <div className=" 2xl:flex px-6 justify-between lg:my-10 gap-6 max-w-screen-2xl mx-auto p-4">
      <div className="grid py-6 lg:py-24 items-center space-y-5 text-center 2xl:text-left 2xl:w-1/2">
        <h4 className="text-2xl lg:text-3xl text-green-600 font-semibold">
          Want to lose weight fast without going to the gym or dieting?
        </h4>
        <h2 className="text-2xl md:text-4xl lg:text-5xl font-extrabold">
          We have the Solution!
        </h2>
        <p className="text-xl font-semibold">
          Best Cool Sculpting in Delhi, Lose Upto 30% Fat Permanently, EMI
          options available.
        </p>
        <div className="flex lg:gap-10 justify-around 2xl:justify-start"></div>
      </div>
      <div className="2xl:w-1/2 my-auto">
        <Swiper
          // onSlideChange={handleSlideChange}
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          rewind={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            speed: 800,
          }}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
            speed: 800,
            effectParams: {
              cubicBezier: "0.16, 1, 0.3, 1",
            },
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="mySwiper"
        >
          {imageArray.map((imageUrl, index) => (
            <SwiperSlide key={index}>
              <img
                className="rounded"
                src={imageUrl}
                alt={`Slide ${index + 1}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Banner;
