import React from "react";

export default function MoreImages() {
  const items = [
    {
      id: 1,
      // src: "https://th.bing.com/th/id/OIP.q1sotVvMiHrceO79hc7BqwHaFU?w=474&h=374&rs=1&pid=ImgDetMain",
      text: "CoolSculpting",
      src: "https://i.ibb.co/Kz2Y24W/Before.png",
    },
    {
      id: 2,
      src: "https://th.bing.com/th/id/OIP.12r8nx2_dYM0VvonIAQBmAHaF2?pid=ImgDet&w=474&h=374&rs=1",
      text: "Cryolipolysis",
    },
    {
      id: 3,
      src: "https://www.sherberandrad.com/wp-content/uploads/2021/05/hump-reduction-bxa-9069.jpg",
      text: "Fat Freezing",
    },
  ];
  return (
    <>
      <div
        name=""
        className="bg-gradient-to-b from-white to-gray-200 w-full text-white md:h-full text-center md:text-left p-4"
      >
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
          {/* <div className="pb-8">
            <p className="text-4xl font-bold  flex items-center justify-center pt-4 pb-4">
              OUR WORK RESULTS - BEFORE AND AFTER{" "}
            </p>
          </div> */}

          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 sm:px-5 py-2">
            {items.map(({ id, src, text }) => (
              <div
                key={id}
                className="shadow-md shadow-gray-600 rounded-lg overflow-hidden bg-black"
              >
                <img
                  src={src}
                  alt=""
                  className="rounded-md duration-200 hover:scale-105"
                />
                <h1 className="text-center font-bold p-3 text-xl">{text}</h1>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
