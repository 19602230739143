
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Icon from "./components/Icon/Icon";
import Home from "./pages/Home";
import Admin from "./pages/admin/Admin";
import NoPage from "./pages/NoPage";

import { collection, getDocs } from "firebase/firestore";
import { db } from "./config/firebase";
import { useState,useEffect } from "react";
import UnderConstruction from "./components/UnderConstruction/UnderConstruction";

function Layout({ children }) {
  const location = useLocation();
  const isHomeRoute = location.pathname === "/";

  return (
    <>
      {isHomeRoute && <Navbar />}
      {children}
      <Icon />
      {isHomeRoute && <Footer />}
    </>
  );
}

export default function App() {
  
  const [check, setCheck] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "check"));
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        if (newData.length > 0) {
          setCheck(newData[0].shouldRun);
        } else {
          console.log("No documents found in the collection");
          setCheck(null); // Or handle this case as needed
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setCheck(null); // Or handle the error case as needed
      }
    };

    fetchData();
  }, []);

  if (check) {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
 }else {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <UnderConstruction />
    </div>
  );
}

}
