import React from 'react'

function UnderConstruction() {
  return (
    <div>
      <h1 className='font-bold text-2xl text-center pt-10 text-black'>Site Under Construction, Coming Soon.</h1>
    </div>
  )
}

export default UnderConstruction