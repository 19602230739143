import React from "react";

export default function ImageGrid() {
  const items = [
    {
      id: 1,
      src: "https://bodyclinix.in/wp-content/uploads/2023/02/6-1.jpeg",
      link: "link here",
    },
    {
      id: 2,
      src: "https://bodyclinix.in/wp-content/uploads/2023/02/5-1.jpeg",
      link: "link here",
    },
    {
      id: 3,
      src: "https://bodyclinix.in/wp-content/uploads/2023/02/7d.jpeg",
      link: "link here",
    },
    {
      id: 4,
      src: "https://bodyclinix.in/wp-content/webp-express/webp-images/uploads/2023/02/3-1.jpeg.webp",
      link: "link here",
    },
    {
      id: 5,
      src: "https://bodyclinix.in/wp-content/webp-express/webp-images/uploads/2023/02/1-1.jpeg.webp",
      link: "link here",
    },
    {
      id: 6,
      src: "https://bodyclinix.in/wp-content/uploads/2023/02/4-1-1.jpeg",
      link: "link here",
    },
  ];
  return (
    <>
      <div
        name=""
        className="bg-gradient-to-b from-black to-gray-800 w-full text-white md:h-full text-center md:text-left "
      >
        <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center w-full h-full">
          <div className="pb-8">
            <p className="text-4xl font-bold  flex items-center justify-center pt-4 pb-4">
              OUR WORK RESULTS - BEFORE AND AFTER{" "}
            </p>
          </div>

          <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 sm:px-5 py-2 ">
            {items.map(({ id, src, link }) => (
              <div
                key={id}
                className="shadow-md shadow-gray-600 rounded-lg overflow-hidden"
              >
                <img
                  src={src}
                  alt=""
                  className="rounded-md duration-200 hover:scale-105"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
