import React from "react";
import Banner from "../components/Home/Banner";
import ContactUs from "../components/Home/ContactUs";
import About1 from "../components/Home/About";
import BeforeAndAfter from "../components/Home/BeforeAndAfter";
import Testimonials from "../components/Home/Testimonials";
import Stats from "../components/Home/Stats";
import MoreImages from "../components/Home/MoreImages";
import Services from "../components/Home/Services";
import AdvanceServices from "../components/Home/AdvanceServices";

function Home() {
  return (
    <div>
      <Banner />
      <ContactUs />
      <About1 />
      <Stats />
      <Services />
      <AdvanceServices />
      <BeforeAndAfter />
      <MoreImages />
      <Testimonials />
    </div>
  );
}

export default Home;
