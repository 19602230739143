import React from "react";

function NoPage() {
  return (
    <div className="text-2xl text-center text-black font-bold m-10">
      Page Not Found!
    </div>
  );
}

export default NoPage;
